.detail-page__header-top-section {
	margin-top: 70px;
	background-image: linear-gradient(to bottom,
			rgba(255, 255, 255, 0),
			rgba(0, 0, 0, 0.4) 100%);
	display: flex;
	flex-grow: 1;
	height: 30px;
}

.detail-page__header-section {
	display: flex;
	gap: 40px;
	justify-content: space-between;
	align-items: flex-start;
	padding-left: 48px;
	padding-right: 48px;
	backdrop-filter: blur(6px);
	background-color: rgba(0, 0, 0, 0.4);
}

.detail-page__header-bottom-section {
	background-image: linear-gradient(to top,
			rgba(255, 255, 255, 0),
			rgba(0, 0, 0, 0.4) 100%);
	display: flex;
	flex-grow: 1;
	height: 30px;
	margin-bottom: 54px;
}

.detail-page__play-button-stats-categories {
	display: flex;
	gap: 20px;
	flex-direction: column;
	margin-top: 70px;
}

.detail-page__game-categories {
	display: flex;
	gap: 12px;
	flex-direction: row;
}

.game-category-pill {
	background: rgba(0, 0, 0, 0.3);
	border: 1px solid rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(6px);
	border-radius: 20px;
	padding: 4px 8px;
	color: white;
	font-size: 10px;
	font-weight: 400;
	text-transform: uppercase;
}

.detail-page__stats {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.monetary-stat {
	border-radius: 12px;
	border: 1px solid rgba(255, 255, 255, 0.2);
	backdrop-filter: blur(6px);
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
	overflow: hidden;
	width: 130px;
}

.monetary-stat__title {
	padding-top: 12px;
	padding-right: 16px;
	padding-left: 16px;
	padding-bottom: 4px;
	font-size: 12px;
	font-weight: 400;
	white-space: nowrap;
	content: "";
	display: inline-block;
	width: min-content;
	position: relative;
}

.monetary-stat__value {
	padding: 0px 16px;
	font-size: 28px;
	font-weight: 500;
	padding-bottom: 4px;
}

.undefined {
	padding-top: 6px;
	font-size: 14px;
	padding-bottom: 14px;
}


.monetary-stat__footer {
	background-color: rgba(255, 255, 255, 0.2);
	font-size: 11px;
	font-weight: 400;
	padding: 2px 16px;
}

.play-now-button {
	background-color: #1a9fff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	height: 54px;
	align-items: center;
	border-radius: 8px;
	color: white;
	font-weight: 500;
	font-size: larger;
	gap: 16px;
	cursor: pointer;
}

.play-now-button:hover {
	background: #38acff;
}

.play-icon {
	width: 24px;
	height: 24px;
}

.featured-icon {
	width: 36px;
	height: 50px;
}

.detail-page__game-info {
	display: flex;
	gap: 10px;
	flex-direction: column;
	width: 700px;
}

.detail-page__game-info__title-image {
	width: 203px;
	height: 103px;
}

.detail-page__game-info__row {
	display: flex;
	flex-direction: row;
	gap: 16px;
}

.developer-info {
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
}

.rating-developer-info__divider {
	width: 1px;
	height: 10px;
	background-color: black;
}

.rating-developer-info {
	font-weight: 500;
	font-size: 14px;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	display: flex;
	gap: 4px;
}

.token-info-wrapper {
	display: flex;
	flex-direction: row;
	gap: 12px;
}

.featured-icon {
	padding-right: 48px;
	width: 36px;
	height: 50px;
}