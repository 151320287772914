.description-widget {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;


    img {
        height: 40px;
        width: 40px;
    }


    .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;

        .title {
            color: white;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25vw
        }

        .sub-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            font-feature-settings: 'case' on;
            color: #B6B8CD;
            order: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 25vw
        }
    }
}